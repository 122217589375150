<template>
  <InternalPageHeader title="View the Inside CKD" title-last-word="Publications"/>
  <div class="publications">
    <div class="container-xxl mt-4">
      <div class="row justify-content-center">
        <div class="col-12 px-2">
          <p class="text-center">The Inside CKD programme was initiated and is funded by AstraZeneca. Inside CKD is designed to project the prevalence and burden of chronic kidney disease (CKD). The programme incorporates the use of microsimulation modelling that was conducted by HealthLumen Ltd. Oversight of the programme was provided by a Scientific Steering Committee comprising 32 members. Use the links below to explore the Inside CKD publications. Please refer directly to the publications in order to cite this work.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="row">
          <!-- Methodology section publication 1 -->
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://doi.org/10.1007/s12325-022-02353-5" id="doi_023535" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">Methodology</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="pubs-download-icon m-auto" />
                  <p class="pubs-title px-4 m-auto">Projecting the Epidemiological and Economic Impact of Chronic Kidney Disease Using Patient-Level Microsimulation Modelling: Rationale and Methods of Inside CKD</p>
                </div>
              </div>
            </a>
          </div>
          <!-- Methodology section publication 2 -->
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://link.springer.com/article/10.1007/s12325-023-02608-9" id="doi_026089" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">Pragmatic review of medical costs</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="pubs-download-icon m-auto" />
                  <p class="pubs-title px-4 m-auto">Global Economic Burden Associated with Chronic Kidney Disease: A Pragmatic Review of Medical Costs for the Inside CKD Research Programme</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://linkinghub.elsevier.com/retrieve/pii/S2589537024001937" id="doi_001937" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">Clinical burden</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="align-self-center pubs-download-icon m-auto" />
                  <p class="pubs-title px-4 m-auto">Projecting the clinical burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://www.thelancet.com/journals/eclinm/article/PIIS2589-5370(24)00194-9" id="doi_102615" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">Economic burden</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="pubs-download-icon m-auto" />
                  <p class="pubs-title px-4 m-auto">Projecting the economic burden of chronic kidney disease at the patient level (Inside CKD): a microsimulation modelling study</p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://linkinghub.elsevier.com/retrieve/pii/S2468024924019491" id="doi_019491" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">UACR projections</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="pubs-download-icon m-auto" />
                  <p class="pubs-title px-4 m-auto">Projecting the population level burden of CKD progression according to urine albumin-to-creatinine ratio categories</p>
                </div>
              </div>
            </a>
          </div>

          <!-- Impact of screening section publication 1 -->
          <div class="col-12 col-lg-6 pub-margin">
            <a href="https://www.kireports.org/article/S2468-0249(25)00045-2/fulltext" id="doi_000452" target="_blank" class="col-12 col-xl-6 mt-xl-0 publication_link_">
              <div class="pub-card p-4 justify-content-between text-center text-sm-start">
                <h4 class="pt-0 pb-2">Impact of screening</h4>
                <div class="d-block d-sm-flex">
                  <img src="img/publication-icon.svg" alt="publication download icon" class="pubs-download-icon m-auto" />
                  <p class="pubs-title ps-3 pe-3 m-auto">Inside CKD: cost-effectiveness of multinational screening for CKD</p>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="px-4 mt-5">
          <p class="footnote"><strong>Abbreviations:</strong> CKD, chronic kidney disease; UACR, urine albumin-to-creatinine ratio.</p>
          <p class="mb-0 pt-1"><small>Date of preparation: March 2025</small></p>
          <p><small>Veeva ID: Z4-72472</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InternalPageHeader from "@/components/InternalPageHeader.vue";

export default {
  name: "PublicationsBlock",
  components: { InternalPageHeader }
};

</script>
